<template>
  <div class="vote">
    <v-card width="500" class="mx-auto mt-8" flat>
      <v-card-title class="red--text">
        <h2>Gracias!</h2>
      </v-card-title>
      <v-card-text>
        <h3>
          {{message}}
        </h3>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <router-link :to="'/'" style="text-decoration: none; color: inherit;">
          <a>
            <h3>Continuar</h3>
          </a>
        </router-link>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import users from "@/services/users";

export default {
  name: "userActivation",
  data: () => ({
    message: null
  }),
  mounted() {
    let self = this;
    users
      .activate(this.$route.params.token)
      .then(response => {
        self.message = response.data.message;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
